<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="updateResetPasswordTemplate" :timeout="4000" top>
      <span>Reset Password Email Templates Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="updateResetPasswordTemplate = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-card>
      <v-card-title> <h3>Reset Password Email Template</h3></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="resetEmailPassword">
              <v-container class="py-2">
                <vue-editor v-model="resetPasswordEmailTemplate"></vue-editor>
                <p style="color: red" v-if="showResetPasswordError">
                  Reset Password Email Templates is required
                </p>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mt-3"
                      @click="submitResetPasswordEmail"
                      :loading="loading"
                      >Update
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row></v-card-text
      >
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "ResetPasswordEmail",
  components: { VueEditor },
  data() {
    return {
      loading: false,
      resetPasswordEmailID: "",
      resetPasswordEmailTemplate: "",
      updateResetPasswordTemplate: false,
      showResetPasswordError: false,
    };
  },
  methods: {
    getResetPasswordEmail() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "setting/edit/reset_password_email_template"
        )
        .then((response) => {
          if (response.status == 200) {
            this.resetPasswordEmailID = response.data.setting._id;
            this.resetPasswordEmailTemplate = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitResetPasswordEmail() {
      if (this.resetPasswordEmailTemplate != "") {
        this.showResetPasswordError = false;
        this.loading = true;
        let data = {
          id: this.resetPasswordEmailID,
          type: "reset_password_email_template",
          value: this.resetPasswordEmailTemplate,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.updateResetPasswordTemplate = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showResetPasswordError = true;
      }
    },
  },
  mounted() {
    this.getResetPasswordEmail();
  },
};
</script>

<style>
</style>